import service from '@/utils/request'

// 正式
const WEIXINAPPLICATION = 'WEIXINAPPLICATION'
const INEWSREPORT = 'INEWSREPORT'
const INEWS = 'INEWSAPI/'
const TEST = 'INEWSAPI'

// 测试地址
// const WEIXINAPPLICATION = 'WEIXINAPPLICATIONTEST'
// const INEWSREPORT = 'INEWSREPORT'
// const INEWS = 'INEWSAPILOCALS/'
// const TEST = 'INEWSAPILOCALS'

//本地测试
// const WEIXINAPPLICATION = ''
// const INEWSREPORT = ''
// const INEWS = ''
// const TEST = ''
// const textUrl = '192.168.0.229:8029/'
export function getClientList(){
    return service({
        url: INEWS+'/inews/user',
        method: 'get',
    })
}

export function getReportModelList(data) {
    return service({
        url: TEST+`/inews/modular/list`,
        method: 'post',
        data
    })
}
export function getWxReportModelList(params) {
    return service({
        url: WEIXINAPPLICATION+`/ModularUser/ModularInfoList`,
        method: 'get',
        params
    })
}


export function getModulesList(params) {
    return service({
        url: INEWS+`/reportTimer/move`,
        method: 'get',
        params
    })
}
export function modularAdd(data) {
    return service({
        // baseURL:'http://192.168.10.108:8090/inews/',
        url: TEST+`/inews/modular/add`,
        method: 'post',
        data
    })
}

export function modularUpdate(data) {
    return service({
        // baseURL:'http://192.168.10.108:8090/inews',
        url: TEST+`/inews/modular/update`,
        method: 'post',
        data
    })
}
export function modularDelete(params) {
    return service({
        url: INEWS+`/inews/modular/delete`,
        method: 'delete',
        params
    })
}
//获取token
export function getWxToken(params) {
    return service({
        url: WEIXINAPPLICATION+`/WeixinAccess/WeixinAccessToken`,
        method: 'get',
        params
    })
}

//获取userId
export function getWxuserId(params) {
    return service({
        url: WEIXINAPPLICATION+`/WeixinAccess/UserDetails`,
        method: 'get',
        params
    })
}

//获取用户UserInfo

export function getWxUserInfo(params) {
    console.log('params',params)
    return service({
        url: WEIXINAPPLICATION+`/WeixinAccess/UserInfo`,
        method: 'get',
        params
    })
}
// 获取微信应用对应机构接口
export function getWeixinAccessOrg(params) {
    return service({
        url: WEIXINAPPLICATION+`/WeixinAccess/WeixinAccessOrg`,
        method: 'get',
        params
    })
}
export function getWeixinAccessAgent(params){
    return service({
        url: WEIXINAPPLICATION+`/WeixinAccess/WeixinAccessAgent`,
        method: 'get',
        params
    })
}

// 获取微用户可现实模块接口
export function getWeixinModular(params) {
    console.log('params',params)
    return service({
        url: WEIXINAPPLICATION+`/ModularUser/ModularUserInfo`,
        method: 'get',
        params
    })
}

//埋点日志统计接口
export function sendBuriedPointTotal(data) {
    return service({
        url: WEIXINAPPLICATION+`/BuriedPointTotal`,
        method: 'post',
        data
    })
}

//
export function getReportTimerReport(data) {
    return service({
        url: WEIXINAPPLICATION+`/ReportTimer/ReportTimerReport`,
        method: 'post',
        data
    })
}

export function getReportList(params) {
    return service({
        url: TEST+`/inews/reportTimer`,
        // url: INEWS+`/inews/reportTimer/move`,
        method: 'get',
        params
    })
}

export function updateReport(id,data) {
    return service({
        url: INEWS+`/inews/reportTimer/${id}`,
        method: 'put',
        data
    })
}

//报告列表页接口
export function getReportTimerInfo(data) {
    return service({
        url: WEIXINAPPLICATION+`/ReportTimer/ReportTimerInfo`,
        method: 'post',
        data
    })
}

// 展示报告列表详情页接口
export function ReportTimerInfoSortList(data) {
    return service({
        url: WEIXINAPPLICATION + `/ReportTimer/ReportTimerInfoSortList`,
        method: 'post',
        data
    })
}

//imgUpaload  图片上穿
export function uploadImg(data) {
    const params = new FormData();
    params.append('imgSamplePath',data)
    return service({
        url: INEWSREPORT+`/DownloadInfo/uploadImage`,
        method: 'post',
        data:params,
    })
}



//获取签名
export function getSignature(params) {
    return service({
        url: WEIXINAPPLICATION+`/WeixinAccess/sign`,
        method: 'get',
        params
    })
}

//获取定时报告有效时间
export function getEffectiveTime(params) {
    return service({
        url: INEWS+`modular/time`,
        method: 'get',
        params
    })
}

//访问统计接口
export function getBuriedPointJournal(data) {
    return service({
        url: WEIXINAPPLICATION+`/BuriedPointTotal/BuriedPointJournal`,
        method: 'post',
        data
    })
}
//用户列表接口
export function getUserList(params) {
    return service({
        url: WEIXINAPPLICATION+`/User/UserList`,
        method: 'get',
        params
    })
}
export function getUserListForControl(params) {
    return service({
        url: WEIXINAPPLICATION+`/WechatParty/User/UserList`,
        method: 'get',
        params
    })
}
//消息通知列表接口
export function getMessageList(data) {
    return service({
        url: WEIXINAPPLICATION+`/SendNotice/List`,
        method: 'Post',
        data
    })
}

//访问统计详情接口
export function getDetaileBuriedPointJournal(data) {
    return service({
        url: WEIXINAPPLICATION+`/BuriedPointTotal/BuriedPointList`,
        method: 'post',
        data
    })
}
export function getAccessRecord(data) {
    return service({
        url: WEIXINAPPLICATION+`/BuriedPointTotal/BuriedPointAllList`,
        method: 'post',
        data
    })
}
export function addModularUserRel(data) {
    return service({
        url: WEIXINAPPLICATION+`/ModularUser/ModularUserRel`,
        method: 'post',
        data
    })
}
export function addModularUserRelForControl(data) {
    return service({
        url: WEIXINAPPLICATION+`/WechatParty/ModularUser/ModularUserRel`,
        method: 'post',
        data
    })
}
//用户更新接口
export function updateModularUserRel(data,id) {
    return service({
        url: WEIXINAPPLICATION+`/User/${id}`,
        method: 'put',
        data
    })
}
export function updateModularUserRelForControl(data,id) {
    return service({
        url: WEIXINAPPLICATION+`/WechatParty/User/${id}`,
        method: 'put',
        data
    })
}
//用户含有的模块
export function getModularUserRel(params) {
    return service({
        url: WEIXINAPPLICATION+`/ModularUser/ModularUserList`,
        method: 'get',
        params
    })
}
export function getModularUserRelForControl(params) {
    return service({
        url: WEIXINAPPLICATION+`/WechatParty/ModularUser/ModularUserList`,
        method: 'get',
        params
    })
}
//用户管理重置
export function getUserRest(params) {
    return service({
        url: WEIXINAPPLICATION+`/User/ResetUser`,
        method: 'get',
        params
    })
}
export function getUserRestForControl(params) {
    return service({
        url: WEIXINAPPLICATION+`/WechatParty/User/ResetUser`,
        method: 'get',
        params
    })
}

//控制台日志
export function getControlLogList(data) {
    return service({
        baseURL:'http://60.247.62.110:10049',
        url: '/clickhouseserverapi/api/v1/search',
        method: 'post',
        data
    })
}

//分类下拉框
export function findModularTypeList(data) {
    return service({
        url: INEWS + `inews/modularType/findModularTypeList/${data}`,
        method: 'get'
    })
}

//分类分页
export function findModularTypeAll(params,pageNum,pageSize) {
    return service({
        url: INEWS + `inews/modularType/findModularTypeAll?pageNum=${pageNum}&pageSize=${pageSize}`,
        method: 'get',
        params
    })
}

//创建分类
export function addModularType(data) {
    return service({
        url: INEWS + `inews/modularType/addModularType`,
        method: 'post',
        data
    })
}

//删除分类
export function delModularTypeLogic(data) {
    return service({
        url: INEWS + `inews/modularType/delModularTypeLogic/${data}`,
        method: 'delete'
    })
}

//编辑分类
export function updateModularType(data) {
    return service({
        url: INEWS + `inews/modularType/updateModularType`,
        method: 'put',
        data
    })
}

// 查询背景
export function findModularStyleByOrgId(data) {
    return service({
        // baseURL:`http://192.168.0.229:8090/inews/modularStyle/findModularStyleByOrgId/${data}`,
        url: INEWS + `inews/modularStyle/findModularStyleByOrgId/${data}`,
        method: 'get'
    })
}

// 查询企业微信背景
export function findWXModularStyleByOrgId(data) {
    return service({
        // baseURL:`http://192.168.0.229:8090/api/findModularStyleByOrgId/${data}`,
        url: INEWS + `api/findModularStyleByOrgId/${data}`,
        method: 'get'
    })
}

// 添加背景
export function addOrUp(data) {
    return service({
        url: INEWS + `inews/modularStyle/addOrUp`,
        method: 'post',
        data
    })
}

// 报告列表详情接口
export function reportTimerInfo(params) {
    return service({
        // baseURL:`http://192.168.0.229:8090/api/findModularStyleByOrgId/${data}`,
        url: INEWS + `inews/reportTimerInfo`,
        method: 'get',
        params
    })
}



// 修改定时报告
export function updateReportTimerInfo(id,data) {
    return service({
        // baseURL:`http://192.168.0.229:8090/api/findModularStyleByOrgId/${data}`,
        url: INEWS + `inews/reportTimerInfo/${id}`,
        method: 'put',
        data
    })
}
