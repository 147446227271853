import {Message} from 'element-ui'
import store from '@/store'
import axios from 'axios'
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

function logWarning(res){
    console.log('process.env.NODE_ENV',process.env.NODE_ENV)
    if (process.env.NODE_ENV !== 'production') {
        return
    }
    const user = store.state.user
    const content = {
        err: res,
        info: res.status,
        href: res.config.url,
        name: user.name,
        phone: user.phone,
    }
    const params = new URLSearchParams();
    params.append('content', JSON.stringify(content));
    params.append('code', 10003);
      axios.post('http://60.247.62.100:8055/LogError', params, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
          }
      }).then(function(logRes) {
      }).catch(function(logError) { // 请求失败处理
      });
}
const service = axios.create({
    baseURL: 'http://api.xlmediawatch.com/',
    // 企业微信
    // baseURL: 'http://192.168.0.229:8029/',
    // baseURL: 'http://192.168.0.229:8090/',
    withCredentials: false,
    timeout: 6000
})
service.interceptors.request.use(
    config => {
        config.headers['Authorization'] = `bearer `+ store.state.token
        config.headers['Access-Control-Allow-Origin'] = '*'
        if (!config.headers['orgId']) {
            config.headers['orgId'] = store.state.orgId ? store.state.orgId : "";
        }
        config.cancelToken = source.token;
        return config
    },
    error => {
        if (exceptionStatus.find(sta => res.status == sta)) {
            logWarning(error)
        }
        console.log(error, "error")
    }
)
const errorMessage = [
    {code: 400, msg: '请求错误'},
    {code: 401, msg: '未授权，请登录'},
    {code: 403, msg: '拒绝访问'},
    {code: 404, msg: '请求地址出错'},
    {code: 408, msg: '请求超时'},
    {code: 500, msg: '服务器内部错误'},
    {code: 501, msg: '服务未实现'},
    {code: 502, msg: '网关错误'},
    {code: 503, msg: '服务不可用'},
    {code: 504, msg: '网关超时'},
    {code: 505, msg: 'HTTP版本不受支持'}
]
// response interceptor
const exceptionStatus = [-1,100]
const noMessage =
    [
    '/clickhouseserverapi/api/v1/search',
    'WEIXINAPPLICATION/WechatParty/User/UserList',
    'WEIXINAPPLICATION/WeixinAccess/WeixinAccessToken',
    'WEIXINAPPLICATION/WeixinAccess/UserDetails',
    'WEIXINAPPLICATION/WeixinAccess/UserInfo',
    'INEWSREPORT/DownloadInfo/uploadImage',
    `WEIXINAPPLICATION/WeixinAccess/sign`,
    'INEWSAPI/inews/modular/list',
    'INEWSAPI/modular/time',
    `WEIXINAPPLICATION/ModularUser/ModularUserInfo`,
    'WEIXINAPPLICATION/ReportTimer/ReportTimerReport',
    `WEIXINAPPLICATION/ReportTimer/ReportTimerInfo`,
    `WEIXINAPPLICATION/BuriedPointTotal`,
    'INEWSAPI/inews/user',
    'INEWSAPI/inews/reportTimer/move',
    'WEIXINAPPLICATION/BuriedPointTotal/BuriedPointJournal',
    'WEIXINAPPLICATION/BuriedPointTotal/BuriedPointList',
    `WEIXINAPPLICATION/SendNotice/List`,
    'INEWSAPI/inews/reportTimer',
    'WEIXINAPPLICATION/User/UserList',
    'WEIXINAPPLICATION/WechatParty/ModularUser/ModularUserList',
    'WEIXINAPPLICATION/WechatParty/ModularUser/ModularUserRel',
    'INEWSAPILOCALS/inews/modular/list',
    'INEWSAPILOCALS/inews/reportTimer',
    'WEIXINAPPLICATION/WeixinAccess/WeixinAccessAgent',
    'WEIXINAPPLICATION/ModularUser/ModularInfoList',
    'WEIXINAPPLICATION/ModularUser/ModularUserList',
    'WEIXINAPPLICATION/BuriedPointTotal/BuriedPointAllList',
        'inews/modularType/updateModularType',
        'inews/modularType/addModularType'
    ]
service.interceptors.response.use(
    response => {
        const res = response.data
        if(!store.state.isWxWork){
            if (exceptionStatus.find(sta => res.status == sta)) {
                Message({
                    message: res.msg ? res.msg : '请求失败',
                    type: 'error',
                })
            }else{
                if(!noMessage.find(item => item ==  response.config.url)){
                    Message({
                        message: res.msg ? res.msg : '请求成功',
                        type: 'success',
                    })
                }

            }
        }
        if (exceptionStatus.find(sta => res.status == sta)) {
            logWarning(response)
        }
        return res.data
    },
    error => {
        if (axios.isCancel(error)) {
            return new Promise(() => {});
        }
        logWarning(error)
        if(!store.state.isWxWork){
            let msg = "未知错误"
            const res = error.response
            if (res) {
                if(!noMessage.find(item => item ==  error.config.url)){
                    msg = errorMessage.find((item) => item.code == res.status).msg || msg
                    Message.error(msg)
                }
            } else {
                if(error.message == 'timeout of 6000ms exceeded'){
                    error.message = errorMessage[4].msg
                    Message.error(errorMessage[4].msg)
                }
            }
        }
        return Promise.reject(error)
    }
)

export default service
